<template>
  <div class="lg:w-1/2 w-full mb-5">
    <h1 class="text-2xl font-bold">{{ $t("business.owner.title") }}</h1>
    <p class="mt-4">
      {{ $t("business.owner.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      v-if="showFormBusinessOwner"
      @submit.prevent="businessOwnerFormSubmit"
    >
      <app-alert-error
        v-if="errors['business_id']"
        :message="errors['business_id'][0]"
        class="my-3 lg:w-7/12 w-full bg-red-100 p-3 rounded-md"
      />

      <app-form-input
        id="first_name"
        name="first_name"
        type="text"
        :labelText="$t('business.owner.label_name_of_person')"
        :placeholder="$t('business.owner.first_name')"
        class="lg:w-7/12 w-full"
        v-model="businessOwner.first_name"
        :errorMessage="errors.first_name"
        :disabled="!editMode"
      />

      <app-form-input
        id="last_name"
        name="last_name"
        type="text"
        :placeholder="$t('business.owner.last_name')"
        class="lg:w-7/12 w-full mb-5"
        v-model="businessOwner.last_name"
        :errorMessage="errors.last_name"
        :disabled="!editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.owner.identification_type"
            value="MyKad"
            v-model="businessOwner.identification_type"
            :disabled="!editMode"
            checked
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="identification_type"
            value="Passport"
            v-model="businessOwner.identification_type"
            :disabled="!editMode"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="identification"
          name="identification"
          :labelText="labelIdentification()"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          v-model="businessOwner.identification"
          :errorMessage="errors.identification"
          :disabled="!editMode"
          :numberOnly="ic_type_input.type === 'number'"
        />
      </div>

      <app-form-telephone-no
        name="phone_no"
        :labelText="$t('business.owner.label_phone_no')"
        class="lg:w-7/12 w-full mb-5"
        v-model="businessOwner.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="!editMode"
      />

      <app-form-input
        id="email"
        name="email"
        type="email"
        :labelText="$t('business.owner.label_email')"
        :placeholder="$t('business.owner.email')"
        class="lg:w-7/12 w-full mt-5"
        v-model="businessOwner.email"
        :errorMessage="errors.email"
        :disabled="!editMode"
      />

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="mt-8 lg:w-7/12 w-full"
      >
        {{ $t("general.add") }}
      </app-button>

      <app-button-outline
        type="button"
        @onClick="showFormAddBusinessOwner(false)"
        class="mt-3 lg:w-7/12 w-full"
      >
        {{ $t("general.back") }}
      </app-button-outline>
    </form>
    <div v-else>
      <div
        class="relative grid grid-cols-2 gap-2 p-5 border my-3 lg:w-7/12 w-full bg-gray-50"
        v-for="owner in business.owners"
        v-bind:key="owner"
      >
        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_name_of_person") }}
          </p>
          <p class="text-gray-600">
            {{ owner.first_name }}
            {{ owner.last_name }}
          </p>
        </div>

        <div>
          <p class="font-semibold">
            {{ owner.identification_type ?? '-' }}
          </p>
          <p class="text-gray-600">{{ owner.identification ?? "-" }}</p>
        </div>

        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_phone_no")}}
          </p>
          <p class="text-gray-600">{{ owner.phone_no ?? "-" }}</p>
        </div>

        <div>
          <p class="font-semibold">
            {{ $t("business.owner.label_email") }}
          </p>
          <p class="text-gray-600">
            {{ owner.email }}
          </p>
        </div>
        
        <app-icon
          @click="deleteBusinessOwner(owner)"
          v-if="editMode"
          name="XIcon"
          class="
            w-5
            mr-3
            top-0
            my-auto
            right-3
            bottom-0
            absolute
            cursor-pointer
          "
        />
      </div>
      <app-button-outline
        v-if="editMode"
        class="relative text-gray-700 lg:w-7/12 w-full"
        @onClick="showFormAddBusinessOwner(true)"
      >
        <app-icon name="PlusIcon" class="absolute w-5 mr-3 left-3" />
        {{
          business.owners?.length > 0
            ? $t("business.owner.add_another_owner")
            : $t("business.owner.add_business_shareholder")
        }}
      </app-button-outline>

      <app-button
        type="button"
        :showf70Icon="true"
        v-if="editMode"
        @click="routeTo('/master-business/business/support-details')"
        v-show="business.owners?.length > 0"
        class="mt-10 lg:w-7/12 w-full"
      >
        {{ $t("general.continue") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      businessOwner: {},
      showFormBusinessOwner: false,
      ic_type: "MyKad",
      ic_type_input: null,
      emptyErrors: {
        first_name: [],
        last_name: [],
        email: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
    errors() {
      const storeErrors = this.$store.getters["masterBusinessStore/errors"];
      if (this.$lodash.isEmpty(storeErrors)) {
        return this.emptyErrors;
      }
      return storeErrors;
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  mounted(){
    this.selectInputType();
  },
  methods: {
    routeTo(name) {
      this.$router.push({
        path: name,
        query: {
          business_id: this.business.id,
        },
      });
    },
     selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: null,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    onSelected(event) {
      this.ic_type = event.target.value;
      this.businessOwner.identification = null;
      this.selectInputType();
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },

    async businessOwnerFormSubmit() {
      this.$store.commit("masterBusinessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });

      this.businessOwner.business_id = this.business.id;

      const result = await this.$store.dispatch(
        "masterBusinessStore/createOrUpdateMasterBusinessOwner",
        this.businessOwner
      );

      if (result == null) return;

      this.$store.dispatch(
        "masterBusinessStore/getMasterBusinessById",
        this.business.id
      );

      this.showFormAddBusinessOwner(false);
    },
    async deleteBusinessOwner(businessOwner) {
      this.$store.commit("masterBusinessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });

      const result = await this.$store.dispatch(
        "masterBusinessStore/deleteMasterBusinessOwner",
        businessOwner
      );

      if (result == null) return;

      this.$store.dispatch(
        "masterBusinessStore/getMasterBusinessById",
        this.business.id
      );
    },
    showFormAddBusinessOwner(value) {
      this.showFormBusinessOwner = value;
      this.businessOwner = {
        identification_type: "MyKad",
      };
      this.ic_type = this.businessOwner.identification_type;
      this.selectInputType();

      this.$store.commit("masterBusinessStore/setErrorMessage", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
